<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Робота із групами тарифів
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12">
                  <v-select
                      :items="operations"
                      v-model="operation"
                      hide-details filled
                      color="grey"
                      label="Операція"
                      :class="!operation ? 'req-star' : ''"
                  />
                </v-col>
                <v-col class="pt-6">
                  <span class="success--text" style="font-size: 1.1rem">Відбір абонентів</span>
                  <v-divider class="mt-1"></v-divider>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-switch
                      class="mt-1"
                      v-model="all_flats"
                      hide-details
                      label="Усі абоненти організації"
                      color="secondary"/>
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect v-model="city_id"
                                        label="Населений пункт" filled
                                        select_type="city"
                                        :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect :parent_id="city_id" v-model="streets"
                                        label="Вулиця(-ки)" multiple filled
                                        select_type="street" use_parent_id
                                        :disabled="!city_id || all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect :parent_id="streets" v-model="buildings"
                                        label="Будинок(-ки)" multiple filled
                                        select_type="building" use_parent_id
                                        :disabled="!streets.length || all_flats"
                  />
                </v-col>
                <v-col class="pt-6">
                  <span class="success--text" style="font-size: 1.1rem">Налаштування послуги</span>
                  <v-divider class="mt-1"></v-divider>
                </v-col>
                <v-col cols="12">
                  <AC_TariffGroup
                      :value="tariff_group_type_id"
                      :class="!tariff_group_type_id ? 'req-star' : ''"
                      :label="operation === 'update' ? 'Група тарифу (попередня)' : 'Група тарифу'"
                      @autocompleteChange="onTariffGroupTypeChange"
                  />
                </v-col>
                <v-col cols="12" v-if="operation === 'update'">
                  <AC_TariffGroup
                      :value="tariff_group_type_id_new"
                      :class="!tariff_group_type_id_new ? 'req-star' : ''"
                      :label="'Група тарифу (нова)'"
                      @autocompleteChange="onTariffGroupTypeNewChange"
                  />
                </v-col>
                <v-col cols="6" v-if="operation !== 'remove'">
                  <date-component v-model="date_start" label="Дата початку" :class_="!date_start ? 'req-star' : ''"/>
                </v-col>
                <v-col cols="6" v-if="operation !== 'remove'">
                  <date-component v-model="date_end" label="Дата закінчення"/>
                </v-col>
                <v-col cols="12">
                  <v-btn depressed text block color="secondary darken-1" class="button-accept"
                         :loading="loading"
                         :disabled="!documentReady()" @click.stop="crud_operation">
                    Виконати
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import fillTablesAPI from '@/utils/axios/fill_tables'
import {ALERT_SHOW} from "@/store/actions/alert";
import {FETCH_TARIFF_GROUP_SELECT} from "@/store/actions/tariff_group";

export default {
  name: "FillTariffGroup",
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    AC_TariffGroup: () => import("@/components/autocomplite/AC_TariffGroup")
  },
  computed: {
    ...mapGetters({
      tariffGroup: 'getTariffGroup'
    })
  },
  data() {
    return {
      formValid: false,
      city_id: null,
      streets: [],
      buildings: [],
      operations: [
        { text: 'Створити', value: 'create' },
        { text: 'Змінити', value: 'update' },
        { text: 'Видалити', value: 'remove' },
      ],
      date_start: null,
      date_end: null,
      operation: null,
      all_flats: false,
      tariff_group_type_id: null,
      tariff_group_type_id_new: null,
      loading: false
    }
  },
  methods: {
    ...mapActions({
      fetchTariffGroup: FETCH_TARIFF_GROUP_SELECT
    }),
    documentReady() {
      if (!this.all_flats) {
        if (!this.streets.length && !this.city_id && !this.buildings.length) {
          return false
        }
      }

      if (this.operation === 'create') {
        return !(!this.tariff_group_type_id || !this.date_start)
      }

      if (this.operation === 'remove') {
        return !(!this.tariff_group_type_id)
      }

      if (this.operation === 'update') {
        return !(!this.tariff_group_type_id || !this.date_start || !this.tariff_group_type_id_new)
      }

    },
    onTariffGroupTypeChange(payload) {
      this.tariff_group_type_id = payload.value
    },
    onTariffGroupTypeNewChange(payload) {
      this.tariff_group_type_id_new = payload.value
    },
    getOperationDescription(payload) {
      if ("create" in payload) {
        return `Створено: ${payload.create} елементів`
      }
      if ("update" in payload) {
        return `Оновлено: ${payload.update} елементів`
      }
      if ("remove" in payload) {
        return `Вилучено: ${payload.remove} елементів`
      }
    },
    crud_operation() {
      if (this.documentReady()) {
        this.loading = true

        const payload = {
          operation: this.operation,
          all_flats: this.all_flats,
          city_id: this.city_id,
          streets: this.streets,
          buildings: this.buildings,
          tariff_group_type_id: this.tariff_group_type_id,
          tariff_group_type_id_new: this.tariff_group_type_id_new,
          date_start: this.date_start,
          date_end: this.date_end
        }
        fillTablesAPI.fill_tariff_group_type(payload)
          .then(response => response.data)
          .then(data => {
            this.$store.dispatch(ALERT_SHOW, { text: this.getOperationDescription(data), color: 'success' })
          })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
            .finally(() => {
              this.loading = false
            })

      }
    }
  },
  created() {
    this.fetchTariffGroup()
  }
}
</script>

<style scoped>

</style>